import SubscriptionPriceModel, {
  SubscriptionPriceModelDto
} from "@/models/SubscriptionPriceModel";

import InvoicingMethod, { InvoicingMethodDto } from "@/models/InvoicingMethod";
import { HttpClient } from "./HttpClient";
import { IDiscountCodeSlim } from "@/models/DiscountCode";
import { AxiosResponse } from "axios";

export class SubscriptionService {
  static async GetSubscriptionPriceModels(): Promise<
    Array<SubscriptionPriceModel>
  > {
    const response = await HttpClient.get("/subscription/price-models");
    if (response.status == 200) {
      return response.data.map(
        (subscriptionPriceModel: SubscriptionPriceModelDto) =>
          new SubscriptionPriceModel(subscriptionPriceModel)
      );
    }
    return [];
  }

  static async GetInvoicingMethods(): Promise<Array<InvoicingMethod>> {
    const response = await HttpClient.get("/subscription/invoicing-methods");
    if (response.status == 200) {
      return response.data.map(
        (invoicingMethod: InvoicingMethodDto) =>
          new InvoicingMethod(invoicingMethod)
      );
    }
    return [];
  }

  static async GetDiscount(code: string): Promise<AxiosResponse> {
    return await HttpClient.get(`/subscription/discount-code?code=${code}`);
  }
}
