export interface ISubscriptionPriceModel {
  id: number;
  basePrice: number;
  nrOfMonths: number;
  monthlyPricePerExtraCategory: number;
  monthlyPricePerMultipleExtraCategories: number;
  monthlyPricePerExtraRegion: number;
  monthlyPricePerMultipleExtraRegions: number;
}

export class SubscriptionPriceModelDto implements ISubscriptionPriceModel {
  id: number = 0;
  basePrice: number = 0;
  nrOfMonths = 0;
  monthlyPricePerExtraCategory = 0;
  monthlyPricePerExtraRegion = 0;
  monthlyPricePerMultipleExtraCategories = 0;
  monthlyPricePerMultipleExtraRegions = 0;
}

export default class SubscriptionPriceModel extends SubscriptionPriceModelDto {
  constructor(dto: SubscriptionPriceModelDto) {
    super();
    Object.assign(this, dto);
  }

  calculateMonthlySubscriptionCost(): number {
    //price + selected categories * price per category and same for region
    return this.basePrice;
  }
}
