export interface IInvoicingMethod {
  id: number;
  method: string;
}

export class InvoicingMethodDto implements IInvoicingMethod {
  id: number = 0;
  method: string = "";
}

export default class InvoicingMethod extends InvoicingMethodDto {
  constructor(dto: InvoicingMethodDto) {
    super();
    Object.assign(this, dto);
  }
}
