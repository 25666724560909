export interface IRegion {
  id: number;
  position: number;
  name: string;
}

export class RegionDto implements IRegion {
  id: number = 0;
  position: number = 0;
  name: string = "";
}

export default class Region extends RegionDto {
  constructor(dto: RegionDto) {
    super();
    Object.assign(this, dto);
  }
}
