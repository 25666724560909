
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { Option } from "@/models/Option";
import { mixin as clickaway } from "vue-clickaway";

@Component({
  components: {
    Multiselect
  },
  mixins: [clickaway]
})
export default class SelectableOptions extends Vue {
  clickedOnce: boolean = false;
  isValid: boolean = true;

  @Watch("optionsProp", { immediate: true, deep: true })
  onOptionsLoad() {
    if (this.optionsProp.length > 0 && this.selectedOptions.length == 0) {
      this.options = [...this.optionsProp];
      if (
        this.selectedOptionsProxy.length > 0 &&
        this.selectedOptions.length == 0
      ) {
        this.selectedOptions = [...this.selectedOptionsProxy];
        this.selectedOptions.forEach(option => {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].id == option.id) {
              this.options.splice(i, 1);
            }
          }
        });
      }
    }
  }

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: "" })
  validationMessage!: string;

  @Prop({ default: () => [] })
  optionsProp!: Array<Option>;

  @Prop({ default: "" })
  placeHolder!: string;

  @Prop({
    default: () => []
  })
  selectedOptionsProxy!: Array<Option>;

  options = Array<Option>();
  selectedOptions = Array<Option>();

  get optionsSortedByAlphabeticalOrder() {
    return this.options.sort(function(a, b) {
      const n1 = a.name.toLowerCase();
      const n2 = b.name.toLowerCase();
      if (n1 < n2) {
        return -1;
      }
      if (n1 > n2) {
        return 1;
      }
      return 0;
    });
  }

  validateInput() {
    if (!this.clickedOnce) {
      return;
    }
    this.isValid = true;
    if (this.required) {
      if (this.selectedOptions.length == 0) {
        this.isValid = false;
      }
    }
  }

  registerClick() {
    this.clickedOnce = true;
  }

  async updateSelected(value: Array<Option>) {
    value.forEach(option => {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id == option.id) {
          this.options.splice(i, 1);
        }
      }
      this.selectedOptions.push(option);
    });
    this.validateInput();
    this.emitChange();
  }

  removeOption(index: number, option: Option): void {
    this.selectedOptions.splice(index, 1);
    this.options.splice(index, 0, option);
    this.emitChange();
  }

  customLabel(option: Option): string {
    return option.name;
  }

  emitChange() {
    this.$emit("change", this.selectedOptions);
  }
}
