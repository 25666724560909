import Region, { RegionDto } from "@/models/Region";
import { HttpClient } from "./HttpClient";

export class RegionService {
  static async GetRegions(): Promise<Array<Region>> {
    const response = await HttpClient.get("/region/regions");
    if (response.status == 200) {
      return response.data.map((region: RegionDto) => new Region(region));
    }
    return [];
  }
}
